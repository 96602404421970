// Импортируйте axiosInstance, если он вам нужен
import router from 'vue-router';
import axiosInstance from '../../helpers/instance';

const state = {
  about: null,
  partners: null,
  customers: null,
  schools: null,
  block: null,
  about_content: null,
  customer_content: null,
  partners_content: null,
  schools_content: null
};

const getters = {};

const actions = {
  async getAbout({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/admin/pages/about_company/get_page_blocks');

      if (response.data) {
        commit('setAbout', response.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async getAboutContent({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/admin/pages/about_company/find_page_block_content?id=' + id,);

      if (response.data) {
        commit('setAboutContent', response.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async getPartnersContent({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/admin/pages/partners/find_page_block_content?id=' + id,);

      if (response.data) {
        commit('setPartnersContent', response.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async getCustomersContent({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/admin/pages/for_customers/find_page_block_content?id=' + id,);

      if (response.data && response.data.data) {
        commit('setCustomersContent', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async deleteCustomerContent({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/admin/pages/for_customers/delete_page_block_content?id=' + id,);

      // if (response.data && response.data.data) {
      //   commit('setCustomersContent', response.data.data);
      // }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async getPartners({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/admin/pages/partners/get_page_blocks');

      if (response.data) {
        commit('setPartners', response.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async getCustomers({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/admin/pages/for_customers/get_page_blocks');

      if (response.data.data) {
        commit('setCustomers', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async getSchools({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/admin/pages/business_school/get_page_blocks');

      if (response.data) {
        commit('setSchools', response.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async getSchoolsContent({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/admin/pages/business_school/find_page_block_content?id=' + id,);

      if (response.data && response.data.data) {
        commit('setSchoolsContent', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async createContent({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      console.log(body, 'dewdewde')
      const response = await axiosInstance.post('/admin/pages/' + body.endpoint, body.payload);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async getBlock({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get(`/admin/pages/${body.endpoint}/find_page_block?id=` + body.block_id);

      if (response.data && response.data.data) {
        commit('setBlock', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async createAndUpdateBlock({ commit }, { endpoint, body }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`admin/pages/${endpoint}/store_or_update_page_block`, body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async deleteContent({ commit }, { endpoint, body }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`admin/pages/${endpoint}/delete_page_block_content`, body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setAbout(state, data) {
    state.about = data;
  },
  setPartners(state, data) {
    state.partners = data;
  },
  setCustomers(state, data) {
    state.customers = data;
  },
  setSchools(state, data) {
    state.schools = data;
  },
  setBlock(state, data) {
    state.block = data;
  },
  setAboutContent(state, data) {
    state.about_content = data;
  },
  setPartnersContent(state, data) {
    state.partners_content = data;
  },
  setSchoolsContent(state, data) {
    state.schools_content = data;
  },
  setCustomersContent(state, data) {
    state.customer_content = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
