// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  products: null,
  categories: null,
  category: null,
  product: null,
  stocks: null
};

const getters = {};

const actions = {
  async getProducts({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const queryparam = id ? `/?category_id=${id}` : ''

      const response = await axiosInstance.get('get_products' + queryparam);

      if (response.data && response.data.data) {
        commit('setProducts', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getCategories({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('get_product_categories');

      if (response.data && response.data.data) {
        commit('setCategories', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getCategory({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('admin/products/categories/edit?id=' + id);

      if (response.data && response.data.data) {
        commit('setCategory', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getProductID({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('admin/products/edit?id=' + id);

      if (response.data && response.data.data) {
        commit('setProduct', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getProductStocks({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('admin/products/get_stocks?product_id=' + id);

      if (response.data && response.data.data) {
        commit('setProductStocks', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createCategory({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/products/categories/store', body);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateCategory({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/products/categories/update', body);

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async createProduct({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/products/store', body);

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async updateProduct({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/products/update', body);

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async deleteProduct({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/products/delete', {
        id: id
      });

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async deleteCategories({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/products/categories/delete', {
        id: id
      });

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};

const mutations = {
  setProducts(state, data) {
    state.products = data;
  },
  setCategories(state, data) {
    state.categories = data;
  },
  setCategory(state, data) {
    state.category = data;
  },
  setProduct(state, data) {
    state.product = data;
  },
  setProductStocks(state, data) {
    state.stocks = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
