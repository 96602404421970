import { createStore } from 'vuex'
import main from "./modules/main"
import auth from "./modules/auth"
import global from './modules/global'
import product from './modules/product'
import profile from './modules/profile'
import blog from "./modules/blog"
import promocodes from "./modules/promocodes"
import users from "./modules/users"
import settings from "./modules/settings"
import pages from "./modules/pages"
import warehouse from './modules/warehouse'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    main,
    auth,
    global,
    product,
    profile,
    blog,
    promocodes,
    users,
    settings,
    pages,
    warehouse
  }
})
