// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  promocodes: null,
  partners: null
};

const getters = {};

const actions = {
  async getPartners({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('admin/partners/index');

      if (response.data && response.data.data) {
        commit('setPartners', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getPromocodes({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('promocodes');

      if (response.data && response.data.data) {
        commit('setPromocodes', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createPromocode({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/promocodes/store', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async updatePromocode({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('admin/promocodes/update', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setPromocodes(state, data) {
    state.promocodes = data;
  },
  setPartners(state, data){
    state.partners = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
