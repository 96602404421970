// Импортируйте axiosInstance, если он вам нужен
import router from 'vue-router';
import axiosInstance from '../../helpers/instance';

const state = {
  profile: null
};

const getters = {};

const actions = {
  async getProfile({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/cabinet/profile/index');

      if (response.data) {
        commit('setProfile', response.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          sessionStorage.removeItem('authAdminToken');
          router.push('/orders')
        }
      }
      throw error;
    }
  },
};

const mutations = {
  setProfile(state, data) {
    state.profile = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
