// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  warehouse: null,
  orders: null,
  status: null,
  order_id: null
};

const getters = {};

const actions = {
  async getWarehouse({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
  
      const response = await axiosInstance.get('warehouse/dashboard');
  
      if (response.data && response.data.data) {
        commit('setWarehouse', response.data.data);
      }
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getWarehouseOrders({ commit }, filter = {}) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
  
      const response = await axiosInstance.get('warehouse/orders', {
        params: filter
      });
  
      if (response.data && response.data.data) {
        commit('setWarehouseOrder', response.data.data);
      }
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getOrderId({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
  
      const response = await axiosInstance.get('warehouse/orders/find?id=' + id);
  
      if (response.data && response.data.data) {
        commit('setWarehouseOrderId', response.data.data);
      }
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getStatus({ commit }, filter = {}) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
  
      const response = await axiosInstance.get('warehouse/orders/statuses');
  
      if (response.data && response.data.data) {
        commit('setStatus', response.data.data);
      }
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async changeStatus({ commit }, event) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
  
      const response = await axiosInstance.post('warehouse/orders/processing/' + event.endpoint, {
        id: event.id
      });
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setWarehouse(state, data) {
    state.warehouse = data;
  },
  setWarehouseOrder(state, data) {
    state.orders = data;
  },
  setStatus(state, data) {
    state.status = data;
  },
  setWarehouseOrderId(state, data) {
    state.order_id = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
