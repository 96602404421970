// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';
import router from '@/router/index.js';

const state = {
  token: sessionStorage.getItem('authAdminToken') || null,
  user: JSON.parse(localStorage.getItem('userAdmin')) || null,
};

const getters = {
  isAuthenticated: state => !!state.token,
  getUser: state => state.user,
};

const actions = {
  async login({ commit }, body) {
    try {
      const response = await axiosInstance.post('/auth/login', body);

      if (response && response.data) {
        const { token, user } = response.data.data;

        if (token) {
          commit('setToken', token);
        }

        if (user) {
          commit('setUser', user);

          if (user.role_id === 3) {
            window.location.replace('/warehouse-dashboard');
          } else {
            window.location.replace('/orders');
          }
        }
      }
      

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  logout({ commit }) {
    commit('clearToken');
    window.location.replace('/')
  }
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    sessionStorage.setItem('authAdminToken', token);
  },
  setUser(state, user) {
    state.user = user;
    localStorage.setItem('userAdmin', JSON.stringify(user)); 
  },
  clearToken(state) {
    state.token = null;
    sessionStorage.removeItem('authAdminToken');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
